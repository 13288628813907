<template>
	<div v-if="canAddDocument">
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn v-if="simpleButtonDisplay && !listTileDisplay" icon @click="addDocument()" v-on="on">
					<v-icon color="primary" class="mr-1">note_add</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.add_file'" />
		</v-tooltip>
		<v-list-tile v-if="listTileDisplay" avatar @click="addDocument()">
			<v-list-tile-avatar>
				<v-icon color="primary">note_add</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'documents.add_file'" />
			</v-list-tile-content>
		</v-list-tile>
		<input v-show="false" :id="addDocumentInputId" multiple type="file" @input="uploadDocuments($event)" />
	</div>
</template>

<script>
import { mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

import NodeCreationMixin from '@/mixins/Documents/NodeCreationMixin'

export default {
	name: 'AddDocumentButton',
	mixins: [DocumentActionModuleGuard, NodeCreationMixin],
	props: {
		parent: {
			type: Object,
			required: false,
			default: null
		},
		selectedElements: {
			type: Array,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			addDocumentInputId: Date.now().toString() + '-' + Math.floor(Math.random() * 100).toString(),
			addDocumentInput: null
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest,
			isFiltering: state => state.documents.isFiltering
		}),
		parentFolder: function () {
			return this.parent?.is_folder ? this.parent : null
		},
		canAddDocument: function () {
			return (
				!this.isGuest &&
				!this.isFiltering &&
				!this.parentFolder?.is_readonly &&
				!this.parentFolder?.is_trash &&
				this.parentFolder?.folder_id &&
				(!this.selectedElements || this.selectedElements.length == 0)
			)
		}
	},
	methods: {
		addDocument: function () {
			if (!this.addDocumentInput) {
				this.addDocumentInput = document.getElementById(this.addDocumentInputId)
			}
			if (this.addDocumentInput) {
				this.addDocumentInput.click()
			}
		},
		uploadDocuments: async function (event) {
			const files = event?.target?.files || event?.files
			const folder = this.parentFolder
			this.___uploadItems(this.selectedVendor, folder, files)
			this.addDocumentInput = null
		}
	}
}
</script>
